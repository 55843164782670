import Careers from 'pages/careers/Careers'
import DummyPHDForm from 'pages/dummyphdform/DummyPHDForm'
import General from 'pages/general'
import MtechForm from 'pages/mtechForm'
import Closed from 'pages/phdform/Closed'
import { Navigate } from 'react-router-dom'

export const baseRoutes = [
  { index: true, element: <Navigate to={'careers'} /> },
  {
    element: <Careers />,
    path: 'careers',
  },
  {
    // element: <PHDForm />,
    element: <Closed />,
    path: 'phdform',
  },
  {
    element: <MtechForm />,
    path: 'mtechform',
  },
  {
    element: <General />,
    path: 'general',
  },

  {
    element: <DummyPHDForm />,
    // element: <Closed />,
    path: 'dummy-phdform-8283',
  },
]
