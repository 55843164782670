import { Box, styled, Typography } from '@mui/material'

function Closed() {
  return (
    <StyledBox>
      <Typography variant="h4">PHD Application Forms are closed !</Typography>
    </StyledBox>
  )
}

export default Closed

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
  width: '100%',
}))
